<template>
  <div>
    <img src="@/assets/solutionpdf/file_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_5.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_6.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_7.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_8.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_9.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_10.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_11.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_12.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_13.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_14.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_15.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_16.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_17.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_18.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_19.jpg" alt="" />
    <img src="@/assets/solutionpdf/file_20.jpg" alt="" />
     <img src="@/assets/solutionpdf/file_21.jpg" alt="" />
      <img src="@/assets/solutionpdf/file_22.jpg" alt="" />
  </div>
</template>

<script>
export default {
  name: "file-manage",
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>